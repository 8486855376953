@import "utils";

.VehicleYearSelector {
  position: relative;

  align-items: center;
  height:rem(58);
  flex-shrink:0;

  .nav-inner {
    display: flex;
    align-items: center;
    flex-shrink:0;
  }

  @include media-desktop {
    background-color: #000;
    height: rem($nav-bar-height);

    .nav-inner {
      width: rem($content-width);

      @include media-tablet {
        width:100%;
        max-width:rem($content-width);
      }

      height: rem($nav-bar-height);
      margin: 0 auto;
      padding: 0 rem(20);
    }
  }

  .wrapper {
    height:rem(48);
    align-items: center;

    @include media-mobile {
      padding-left:rem(15);
    }
    @include media-desktop {
      display: flex;
      &:first-child > a {
        margin-left:rem(-6);
      }
    }

    & > a {
      display: block;
      text-decoration: none;
      line-height:normal;
      color: white;
      position: relative;
      font-size: rem(20);
      opacity:.4;
      margin: rem(10) rem(8) 0 rem(8);
      padding:0 rem(8);
      cursor: pointer;

      transition: all .2s;

      @include media-mobile {
        margin-left:0;
      }
      @include media-desktop {
        font-size:rem(18);
        margin:0 rem(10) rem(4) rem(10);
        padding:0 rem(10);
      }

      &.current {
        opacity:1;
        transform-origin: center center;
        transform: scale(1.2) translateY(-1px);

        &:after {
          content: '';
          display: block;
          position: absolute;
          width:rem(5);
          height:rem(5);
          background: white;
          border-radius: 100%;
          top: 100%;
          left: 50%;
          margin-left:rem(-3);
          margin-top: 1px;
        }
      }
    }
  }
}
