@import 'common';

.Vehicles {
  @extend %Screen;

  @include media-desktop {
    .sticky-vehicle-year-selector {
      min-height: rem($nav-bar-height);

      .sticky {
        width: 100%;
        z-index: 5;
        top: 0;
      }
    }
  }
}
