.Rating {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 24px 0;

  &__star {
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;

    &:hover, 
    &--selected {
      svg path {
        fill-opacity: 1;
        transition: all .3s ease-in;
      }
    }
  }
}
