@import "common";

.VehicleFilter {
  display: flex;
  flex-flow: row nowrap;

  @include media-desktop() {
    position: relative;
  }

  .filter-trigger {
    display: flex;
    box-sizing: content-box;
    align-items:center;
    cursor: pointer;
    font-weight:bold;

    &:after {
      margin-left: rem(5);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 3.5px 0 3.5px;
      border-color: #000000 transparent transparent transparent;
    }

    @include media-desktop() {
      &:after {
        border-width: 5px 4px 0 4px;
        margin-left: rem(8);
      }
    }
  }
  .filter-inner {
    display: flex;
    width: 100%;
    max-height: 0;
    position: absolute;
    top: rem(68);
    left:0;
    flex-flow:column nowrap;
    padding:rem(20) rem(10);

    visibility: hidden;
    opacity:0;
    background: #fff;
    z-index:3;

    @include media-mobile {
      box-shadow: inset 0 1px #E2E2E2, 0 rem(10) rem(15) rem(-5) rgba(0,0,0,0.2);
    }

    @include media-desktop() {
      padding: 0;
      width:auto;
      min-width: rem(200);
      right:0;
      left:auto;
      top: rem(50);
      border-radius: rem(10);
      box-shadow: 0 rem(5) rem(10) 0 rgba(0,0,0,0.2);
    }

    li {
      display: flex;
      align-items: center;
    }
  }
  .filter-link {
    display: block;
    width:100%;
    position: relative;
    font-size:rem(18);
    line-height: rem(24);
    padding:rem(10) rem(20) rem(10) rem(40);
    margin: 0 rem(10);
    cursor: pointer;
    color: #000;
    text-decoration: none;
    text-transform: none;
    text-align: left;
    font-weight:normal;

    @include media-desktop {
      white-space:nowrap;
      padding: rem(15) rem(20);
      margin:0;
    }

    .icon.icon-check {
      display: none;
      position: absolute;
      left: 0;
      width: rem(20);
      height: rem(20);

      .stroke {
        stroke: #32B324;
      }
    }

    &.current {
      @include media-desktop {
        background-color: #e9eaea;
        font-weight: bold;
      }
      @include media-mobile {
        & .icon.icon-check{
          display: block;
        }
      }
    }
  }
  &.opened {
    .filter-trigger:after {
      border-width: 0 3.5px 4px 3.5px;
      border-color: transparent transparent #000000 transparent;
    }
    .filter-inner {
      visibility: visible;
      opacity:1;
      max-height: rem(500);
    }
    .filter-wrapper {
      display: block;
    }
    @include media-desktop() {
      .filter-trigger:after {
        border-width: 0 4px 5px 4px;
      }
    }
  }
}
