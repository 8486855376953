@import 'common';

.ContentList {
  $list-color: #495360;
  $icon-color: #dfdfdf;

  font-size: rem(18);
  font-weight: 100;
  line-height: rem(28);
  @include media-mobile {
    padding-left:rem(20);
  }

  li {
    box-shadow: inset 0 1px #cfdbe6;
    @include media-desktop() {
      box-shadow: none;
    }

    display: flex;

    &:first-child {
      box-shadow: none;
    }

    .item-inner {
      display: flex;
      flex-flow: row nowrap;
      text-decoration: none;
      font-weight: 300;
      position: relative;
      flex: 1;
      padding: rem(16) rem(20) rem(16) 0;
      align-items: center;

      .item-title {
        flex: 1;
        padding-left: rem(20);
        color: $list-color;
        transition: all .2s;

        &.return {
          //color: lighten($list-color, 20%);
          font-weight:300;
        }

        .highlighted {
          span {
            opacity:.6;
          }
          mark {
            background-color: #f5f5f5;
          }
        }
      }

      .icon.icon-list-pdf {
        .fill {
          fill: #25668e;
        }
        .stroke {
          stroke: #25668e;
        }
      }

      @include media-desktop {
        //transition: all .2s;
        //transform-origin: 0 0;
        padding: rem(10) 0 rem(10) 0;
        align-items: center;

        .item-title {
          padding-left:rem(22);
          padding-right:0;
          font-weight:300;
          font-size:rem(20);
        }

        .item-iconbar {
          .icon {
            width:rem(20);
          }
        }

        &:hover {
          //transform: scale(1.02);

          .item-title {
            color: #000;
          }
          .icon .fill {
            fill: darken($icon-color, 10%);
          }
          .icon.icon-list-pdf {
            .fill {
              fill: darken(#25668e, 10%);
            }
            .stroke {
              stroke: darken(#25668e, 10%);
            }
          }
        }
      }
    }

    .item-media {
      padding:0 rem(10) rem(16) 0;

      @include media-desktop {
        padding-top: rem(2);
      }
      cursor: pointer;

      .icon {
        display: block;
        width: rem(24);

        &.icon-bookmark .stroke {
          stroke: #BAC5D1;
        }

        &.icon-bookmark.active {
          .fill {
            fill: #7F8D97;
          }
          .stroke {
            stroke: #7F8D97;
          }
        }

        &.icon-arrow-return .fill {
          fill: lighten(#BAC5D1, 5%);
        }
      }
    }

    .item-iconbar {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      .icon {
        display: block;
        width: rem(16);

        @include media-mobile {
          width: rem(22);
        }

        .fill {
          transition: fill .2s;
          fill: $icon-color;
        }


        &.icon-arrow-left {
          width: rem(8);
          height:rem(14);
          transform: rotate(180deg);

          .stroke {
            stroke: $list-color;
          }

        }
      }
    }
  }
}
