.RadioInputGroup {
  display: flex;
  flex-flow: column;
  gap: 16px;

  &__label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .RadioInput {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    input {
      visibility: hidden;
      position: absolute;
    }

    label {
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.86);
      cursor: pointer;
    }
  }
}
