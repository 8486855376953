@import "../utils";
@import "themes";

// =========================
// theme specific styling
// =========================

// Logo
.logo, .screen-logo {
  @include themify($themes) {
    background-image: themed('logo');
    background-position: 50% 50%;
  }
}

@include media-desktop {
  .screen-topmenu .topmenu-link {
    font-weight: bold;
  }

  .screen-head {
    width: rem($content-width);
    margin: 0 auto
  }
}

// Splash screen colors
.Vehicles, .Promo, .Guide {
  @include themify($themes) {

    @include media-desktop() {
      background: themed('backgroundColor');
      color: themed('contrastColor');
    }

    @include media-mobile() {
      background: themed('primaryColor');
      color: themed('backgroundColor');
    }

    .screen-title {

      color: themed('contrastColor');

      @include media-desktop {
        em {
          color: themed('titleColor');
        }
      }

      @include media-mobile() {
        em {
          color: themed('backgroundColor');
        }
      }
    }
    .screen-overlay {
      background: themed('primaryColor');
      color: themed('backgroundColor');

      .PrimaryButton {
        color: themed('contrastColor');
        background: themed('backgroundColor');
      }

      .icon .fill {
        fill: rgba(themed('backgroundColor'), .5);
      }
    }
    @include media-desktop() {
        @if (themed('shortHeader')) {
          .screen-head {
            height: rem(85);
          }
          .screen-title.title-long {
            em {
              line-height: 1;
            }
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-right: rem(20);
              line-height: 1.4;
              margin-bottom: rem(-3);
            }
          }
          @if (themed('logoSize')) {
            .screen-logo {
              max-width: 7.95rem;
              height: 3rem;
              align-self: center;
            }
          }
          .sticky-nav-bar {
            top: rem(85);
          }
        }
    }
    @include media-mobile() {
      .screen-head {
        background-color: transparent;
        box-shadow: none;
      }

      .screen-title, .screen-back > * {
        color: themed('backgroundColor');
      }
      .screen-back .icon .stroke {
        stroke: themed('backgroundColor');
      }

      .screen-topmenu {
        .topmenu-trigger .icon .fill {
          fill: themed('backgroundColor');
        }
        .topmenu-inner {
          box-shadow: 0 rem(10) rem(15) rem(-5) rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}

.EditorToolsScreen {
  @include media-desktop {
    .screen-head {
      @include themify($themes) {
        box-shadow: inset 0 -1px #E2E2E2;
      }
    }
  }
}

// Vehicle selector styling
.Vehicles {
  @include themify($themes) {
    @include media-desktop() {
        background: themed('backgroundColor');
        color: themed('contrastColor');
    }

    .VehicleYearSelector {
      @include media-desktop {
        background-color: themed('primaryColor');
        box-shadow: 0 rem(3) rem(6) 0 rgba(themed('regularColor'), .7);
      }

      @include media-mobile() {
        .slick-list:after {
          content: ' ';
          display: block;
          position: absolute;
          width: rem(50);
          height: 100%;
          right: 0;
          bottom: 0;
          box-shadow: inset rem(-50) 0 rem(40) rem(-20) rgba(themed('primaryColor'), .9);
        }
        .wrapper a {
          color: themed('backgroundColor');
          font-weight: 300;
        }
      }
    }

    .VehicleList {
      @include media-mobile {
        background: themed('backgroundColor');

        .vehicles-bar {
          z-index: 2;
          background: themed('backgroundColor');
          box-shadow: 0 rem(3) rem(7) rem(1) rgba(#000, .15);
        }
      }

      @include media-desktop {
        .vehicles-bar {
          box-shadow: inset 0 -1px #E2E2E2;
        }

        .sticky {
          background-color: white;
          .vehicles-bar {
            background-color: white;
            box-shadow: none;
          }
        }
      }
    }

    .VehicleListItem {

      .heroshot .product-type-icon .fill {
        fill: themed('heroshotColor', 'regularColor');
      }

      @include media-mobile {
        box-shadow: 0 rem(3) rem(7) rem(1) rgba(#000, .15);
      }

      @include media-desktop {
        .title {
          color: themed('contrastColor');
        }
      }
    }
  }
}

// Regular screen colors
.Guide {
  @include themify($themes) {
    color: themed('regularColor');
    background: themed('backgroundColor');
  }

  @include media-mobile() {
    .screen-head {
      @include themify($themes) {
        background-color: themed('primaryColor');
      }
      box-shadow: none;
    }
  }

}

//loader styling
.Loader {
  @include themify($themes) {
    background-color: themed('secondaryColor');

    .loader-default > div {
      background-color: themed('backgroundColor');
    }
    .loader-ios {
      @keyframes spinner-ios-#{$currentTheme} {
        0% {
          background-color: themed('backgroundColor');
        }
        100% {
          background-color: transparent;
        }
      }
      @keyframes spinner-ios-#{$currentTheme}-inverse {
        0% {
          background-color: themed('secondaryColor');
        }
        100% {
          background-color: transparent;
        }
      }
      & > div {
        animation-name: spinner-ios-#{$currentTheme};
      }
    }
    .loader-android circle {
      stroke: themed('backgroundColor');
    }
  }

  &.inverse {
    @include themify($themes) {
      background-color: themed('backgroundColor');
      color: themed('regularColor');

      .loader-default > div {
        background-color: themed('loaderColor', 'secondaryColor');
      }
      .loader-ios > div {
        animation-name: spinner-ios-#{$currentTheme}-inverse;
      }
      .loader-android circle {
        stroke: themed('loaderColor', 'secondaryColor');
      }
    }
  }
}

// Bottom bar styling
.NavBar {
  @include media-desktop() {
    @include themify($themes) {
      box-shadow: 0 rem(3) rem(6) 0 rgba(themed('regularColor'), .7);
      background-color: themed('primaryColor');
    }

    .nav-link {
      font-weight: bold;
    }
  }

  @include media-mobile() {
    @include themify($themes) {
      background-color: themed('navbarBackgroundColor', 'backgroundColor');


      .nav-link {
        color: themed('regularColor');

        .icon {
          .active {
            display: none;
          }
          .fill {
            fill: themed('regularColor');
          }
          .stroke {
            stroke: themed('regularColor');
          }
        }

        &.active {
          color: themed('navbarIconColor', 'secondaryColor');

          .icon .active {
            display: inherit;
          }
          .icon .inactive {
            display: none;
          }
          .icon .fill {
            fill: themed('navbarIconColor', 'secondaryColor');
          }
          .icon .stroke {
            stroke: themed('navbarIconColor', 'secondaryColor');
          }
        }
      }
    }
  }
}

// Buttons
.PrimaryButton {
  @include themify($themes) {
    color: themed('backgroundColor');
    background: themed('primaryColor');
  }
}
.SecondaryButton {
  @include themify($themes) {
    color: themed('regularColor');
    background: themed('backgroundColor');
    border: 1px solid rgba(themed('primaryColor'), .3);
    font-weight: 400;
  }
}

.ErrorMessage:not(.inverse) {
  @include themify($themes) {
    background-color: themed('primaryColor');
    color: themed('backgroundColor');
    .icon .fill {
      fill: rgba(themed('backgroundColor'), .5);
    }
    h1 {
      color: themed('backgroundColor');
    }
    span {
      color: rgba(themed('backgroundColor'), .5);
    }
  }
}

.Promo {
  @include themify($themes) {
    .overlay {
      background-color: themed('promoOverlayColor');;
    }

    .head {
      background-color: themed('promoHeadBackgroundColor', 'primaryColor');

      .promo-logo {
        background-image: themed('promoLogo', 'logo');
        max-width: rem(nth(themed('promoLogoSize'), 1)); //logo height
        height: rem(nth(themed('promoLogoSize'), 2));    //logo width
      }
    }

    .container {
      background: themed('promoBackgroundImage') no-repeat center center fixed;
      background-size: cover;
    }

    .navigation {
      .store-badge {
        background: linear-gradient(to bottom right, transparent 50%, themed('promoNavbarColor', 'primaryColor') 50%);
      }

      .browse {
        background-color: themed('promoNavbarColor', 'primaryColor');

        a {
          color: themed('promoNavLinkColor');
        }
      }
    }
  }
}

.Topics {
  @include themify($themes) {
    .video-container .video-markers-container .video-markers {
      button {
        color: themed('primaryColor');

        @include media-desktop {
          &:hover {
            color: darken(themed('primaryColor'), 20%);
          }
        }
      }
    }

    @include media-desktop {
      .favorites-topic-list {
        padding-top: rem(15);
      }
    }

    .icon-bookmark {
      &.active {

        .fill {
          fill: themed('secondaryColor');
        }

        .stroke {
          stroke: themed('secondaryColor');
        }
      }

      .fill {
        fill: themed('regularyColor');
      }

      .stroke {
        stroke: themed('regularyColor');
      }
    }

    @include media-mobile {
      .topic-title {
        font-weight: bold;
      }

      .topics-subtitle {
        z-index: 2;
        background: themed('backgroundColor');
        box-shadow: 0 rem(3) rem(7) rem(1) rgba(#000, .15);
      }

      .SearchInput {
        z-index: 2;
        background: themed('backgroundColor');
        box-shadow: 0 rem(3) rem(7) rem(1) rgba(#000, .15);
      }

      .topics .topics-subtitle {
        z-index: 1;
        box-shadow: inset 0 -1px #E2E2E2;
      }

      .topics-subtitle.no-shadow {
        box-shadow: inset 0 -1px #E2E2E2;
      }
    }

    @include media-desktop {

    }

    .topics-breadcrumb li a,
    .topics-breadcrumb li span {
      font-weight: bold;
    }

    .topic-content {
      pre.codeblock code a {
        background-color: themed('primaryColor');
      }
    }
  }

  .toast-message-container {
    @include themify($themes) {
      color: themed('backgroundColor');
      background-color: themed('toastColor', 'secondaryColor');
    }
  }
}

.TopicNavigation {
  .topic-nav-wrapper {
    & > a {
      @include themify($themes) {
        color: themed('navlinkColor');

        .topic-nav-link-direction .icon.icon-nav-arrow .fill {
          fill: themed('navlinkColor');
        }

        @include media-mobile {
          color: themed('primaryColor');
          background-color: themed('navButtonColor');

          .topic-nav-link-direction .icon.icon-nav-arrow .fill {
            fill: themed('primaryColor');
          }
        }
      }
    }
  }
}

.ContentList {
  li {
    .item-inner {
      @include media-desktop {
        .item-iconbar {
          .icon-list-topic {
            margin-right: rem(5);
          }
        }
      }

      @include media-mobile {
        align-items: center;

        .item-iconbar {
          .icon-list-topic {
            height: rem(20);
          }

          .icon {
            margin: 0;
          }
        }
      }

      .item-title {
        @include themify($themes) {
          color: themed('titleColor');

          &:hover {
            color: themed('contrastColor');
          }

          .highlighted {
            span {
              opacity: 1;
            }
            mark {
              background-color: themed('backgroundColor');
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.RelatedContentsTabView {
  flex-shrink: 0;

  .tabs {
    @include themify($themes) {
      color: themed('titleColor');

      .tab-activated {
        box-shadow: inset 0 -4px themed('primaryColor');
        color: themed('primaryColor')
      }
    }
  }
}

.RelatedContentList {
  @include media-desktop {
    @include themify($themes) {
      .item-inner {
        .item-title {
          color: themed('navlinkColor');
        }

        .item-iconbar .icon {
          .fill {
            fill: themed('navlinkColor');
          }
          .stroke {
            stroke: themed('navlinkColor');
          }
        }

        &:hover {
          .item-title {
            color: darken(themed('navlinkColor'), 20%);
          }

          .item-iconbar .icon {
            .fill {
              fill: darken(themed('navlinkColor'), 20%);
            }
            .stroke {
              stroke: darken(themed('navlinkColor'), 20%);
            }
          }
        }
      }
    }
  }
}

.AttachmentButton {
  @include themify($themes) {
    .attachment-button {
      background-color: themed('secondaryColor');
    }

    .icon-attachment .fill {
      color: themed('backgroundColor');
    }
  }
}

.OwnersManualPdfDropdown {
  @include themify($themes) {
    .pdf-link {
      color: themed('navlinkColor');

      &:hover {
        color: darken(themed('navlinkColor'), 20%);
      }
    }
  }
}
