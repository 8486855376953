@import 'common';

.vjs-youtube {
  .vjs-tech {
    z-index: 10;
  }

  .vjs-loading-spinner {
    display: none;
  }

  .vjs-poster {
    display: none !important;
  }
}
