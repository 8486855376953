// expand styles inside this mixin for each theme from list of $themes
@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {

    .theme-#{$theme} & {
      $theme-map: () !global;
      $currentTheme: $theme !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }

  }
}

// get the concrete value from theme parameters
// if both 'key' and 'fallbackKey' are not available in current theme, the values from 'default' one will be used
@function themed($key, $fallbackKey: '') {
  $default: map-get($themes, 'default');
  $theme: map-get($theme-map, $key);
  @if ($theme) {
    @return $theme;
  } @else {
    @if (map-get($theme-map, $fallbackKey)) {
      @return map-get($theme-map, $fallbackKey)
    } @else {
      @if (map-get($default, $key)) {
        @return map-get($default, $key)
      } @else {
        @return map-get($default, $fallbackKey)
      }
    }
  }
}

// list of all themes with params included
$themes: (
  default: (
    logo: url('../../images/generic_logo.png'),
    primaryColor: #000,
    secondaryColor: #333,
    backgroundColor: #fff,
    contrastColor: #000,
    regularColor: #7c8d98,
    titleColor: #4f4f4f,
    navButtonColor: #f2f2f2,
    heroshotColor: #cccccc,
    navlinkColor: #25668e,

    promoLogo: url('../../images/generic_logo.png'),
    promoLogoSize: 60 60,
    promoHeadBackgroundColor:  #000,
    promoNavbarColor: #000,
    promoBackgroundImage: url('../../images/promo-bg-rv.png'),
    promoNavLinkColor: #f2f2f2,
    promoOverlayColor: rgba(0, 0, 0, 0.4),

    // a flag for different header styling used for some of brands
    shortHeader: false,
  ),
  berkshire: (
    logo: url('../themes/logos/berkshire.png'),
    primaryColor: #134336,
    secondaryColor: #317161,
    navButtonColor: #f1f3f3,
    heroshotColor: #d0dad7,
    shortHeader: true,
    logoSize: 210 25,
    promoLogoSize: 160 19,
    promoBackgroundImage: url('../../images/promo-bg-pontoon.png'),
    promoOverlayColor: rgba(0, 67, 97, 0.36),
  ),
  cargo-mate: (
    logo: url('../themes/logos/cargo-mate.png'),
    primaryColor: #000000,
    secondaryColor: #4a4a4a,
    navbarIconColor: #d91b1b,
    navButtonColor: #f8f8fd,
    promoLogoSize: 77 40,
    promoBackgroundImage: url('../../images/promo-bg-cargo.png'),
    promoNavbarColor: #d91b1b,
  ),
  coachmen: (
    logo: url('../themes/logos/coachmen.png'),
    primaryColor: #0e7eaf,
    secondaryColor: #0e7eaf,
    toastColor: #313439,
    heroshotColor: #daeafa,
    promoLogoSize: 112 32,
    promoLogo: url('../themes/logos/coachmen-promo.png'),
    promoBackgroundImage: url('../../images/promo-bg-rv.png'),
    promoHeadBackgroundColor: #252525,
  ),
  continental-cargo: (
    logo: url('../themes/logos/continental-cargo.png'),
    primaryColor: #000000,
    secondaryColor: #a28801,
    promoLogoSize: 77 31,
    promoBackgroundImage: url('../../images/promo-bg-cargo.png'),
    promoNavbarColor: #a28801,
  ),
  dynamax: (
    logo: url('../themes/logos/dynamax.png'),
    primaryColor: #004794,
    secondaryColor: #84175e,
    heroshotColor: #ccdbea,
    shortHeader: true,
    logoSize: 156 30,
    promoLogoSize: 109 22,
    promoLogo: url('../themes/logos/dynamax-promo.png'),
    promoBackgroundImage: url('../../images/promo-bg-rv.png'),
    promoNavbarColor: #84175e,
  ),
  forest-river: (
    logo: url('../themes/logos/forest-river.png'),
    primaryColor: #104573,
    secondaryColor: #104573,
    navbarIconColor: #323031,
    heroshotColor: #d3d9de,
    loaderColor: #0e3f5f,
    promoLogoSize: 69 38,
    promoBackgroundImage: url('../../images/promo-bg-rv.png'),
  ),
  haulin-trailers: (
    logo: url('../themes/logos/haulin.png'),
    primaryColor: #000000,
    secondaryColor: #333132,
    navbarIconColor: #d91b1b,
    shortHeader: true,
    logoSize: 132 35,
    promoLogoSize: 108 29,
    promoBackgroundImage: url('../../images/promo-bg-cargo.png'),
    promoNavbarColor: #ee1c25,
  ),
  lightning-trailers: (
    logo: url('../themes/logos/lightning.png'),
    primaryColor: #2b2b2b,
    secondaryColor: #ffcb0a,
    toastColor: #4a4a4a,
    navbarIconColor: #feca09,
    navbarBackgroundColor: #2b2b2b,
    heroshotColor: #d5d5d5,
    loaderColor: #2b2b2b,
    shortHeader: true,
    logoSize: 133 41,
    promoLogoSize: 100 31,
    promoLogo: url('../themes/logos/lightning-promo.png'),
    promoBackgroundImage: url('../../images/promo-bg-cargo.png'),
    promoNavbarColor: #ffcb0a,
    promoNavLinkColor: #252525,
  ),
  palomino: (
    logo: url('../themes/logos/palomino.png'),
    primaryColor: #312823,
    secondaryColor: #b96236,
    navbarIconColor: #b96236,
    heroshotColor: #d6d4d3,
    shortHeader: true,
    logoSize: 189 30,
    promoLogoSize: 108 14,
    promoLogo: url('../themes/logos/palomino-promo.png'),
    promoBackgroundImage: url('../../images/promo-bg-rv.png'),
  ),
  prime-time-manufacturing: (
    logo: url('../themes/logos/prime-time.png'),
    primaryColor: #003058,
    secondaryColor: #003058,
    toastColor: #333132,
    promoLogoSize: 111 31,
    promoBackgroundImage: url('../../images/promo-bg-rv.png'),
    promoLogo: url('../themes/logos/prime-time-promo.png'),
    promoNavbarColor: #004794,
  ),
  rance-aluminum: (
    logo: url('../themes/logos/rance.png'),
    primaryColor: #000000,
    secondaryColor: #4a4a4a,
    shortHeader: true,
    logoSize: 139 37,
    promoLogoSize: 81 22,
    promoLogo: url('../themes/logos/rance-promo.png'),
    promoBackgroundImage: url('../../images/promo-bg-cargo.png'),
    promoNavbarColor: #d91b1b,
  ),
  shasta: (
    logo: url('../themes/logos/shasta.png'),
    primaryColor: #1d506b,
    secondaryColor: #1d506b,
    navbarIconColor: #0c5d72,
    heroshotColor: #d2dce2,
    promoLogoSize: 85 34,
    promoBackgroundImage: url('../../images/promo-bg-rv.png'),
  ),
  south-bay: (
    logo: url('../themes/logos/south-bay.png'),
    primaryColor: #063844,
    secondaryColor: #0c5d72,
    navButtonColor: #f0f5f7,
    heroshotColor: #ced8da,
    shortHeader: true,
    logoSize: 265 30,
    promoLogoSize: 221 21,
    promoBackgroundImage: url('../../images/promo-bg-pontoon.png'),
    promoOverlayColor: rgba(0, 67, 97, 0.36),
  ),
  trifecta: (
    logo: url('../themes/logos/trifecta.png'),
    primaryColor: #000000,
    secondaryColor: #525252,
    shortHeader: true,
    logoSize: 180 19,
    promoLogoSize: 157 17,
    promoBackgroundImage: url('../../images/promo-bg-pontoon.png'),
    promoOverlayColor: rgba(0, 67, 97, 0.36),
  ),
  us-cargo: (
    logo: url('../themes/logos/us-cargo.png'),
    primaryColor: #045990,
    secondaryColor: #2077c1,
    navButtonColor: #f4f8fb,
    heroshotColor: #cddee9,
    promoLogoSize: 42 39,
    promoLogo: url('../themes/logos/us-cargo-promo.png'),
    promoBackgroundImage: url('../../images/promo-bg-cargo.png'),
    promoHeadBackgroundColor: #000000,
    promoNavbarColor: #1f76c0,
  ),
  east-to-west: (
    logo: url('../themes/logos/east-to-west.png'),
    primaryColor: #015880,
    secondaryColor: #333132,
    navButtonColor: #323031,
    heroshotColor: #cddee9,
    promoLogoSize: 120 45,
    // promoBackgroundImage: url('../../images/promo-bg-cargo.png'),
    promoHeadBackgroundColor: #000000,
    promoNavbarColor: #1f76c0,
  ),
  cargo-king: (
    logo: url('../themes/logos/cargoking.png'),
    primaryColor: #000,
    promoBackgroundImage: url('../../images/promo-bg-cargo.png'),
  ),
  ameralite: (
    logo: url('../themes/logos/ameralite.png'),
    primaryColor: #392b9a,
    promoBackgroundImage: url('../../images/promo-bg-cargo.png'),
  ),
  force: (
    logo: url('../themes/logos/force.png'),
    primaryColor: #313131,
    promoBackgroundImage: url('../../images/promo-bg-cargo.png'),
  ),
  nepallo: (
    logo: url('../themes/logos/nepallo.png'),
    primaryColor: #142541,
    promoBackgroundImage: url('../../images/promo-bg-pontoon.png'),
  ),
);
