@import 'common';

.Article {
  @include media-desktop() {
    .screen-container {
      max-width: 813px;
      margin: 0 auto;
    }
  }

  @include media-mobile() {
    .article-topic-content {
      margin: rem(20);

      iframe {
        // we need to force override the WYSIWYG editor styling on desktop
        max-height: 175px!important;
      }
    }
  }

  // Styles below are imported from organizer/cms-ui project
  // artcle.css, editor.css
  .article-topic-content {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300 !important;
    color: #000;

    * {
      font: inherit;
    }

    img {
      position: relative;
      max-width: 100%;
    }

    img.fr-dib {
      margin: 5px auto;
      display: block;
      float: none;
    }

    p {
      display: block;
      -webkit-margin-before: 1em;
      -webkit-margin-after: 1em;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
    }

    div {
      display: block;
    }

    layer {
      display: block;
    }

    article,
    aside,
    footer,
    header,
    hgroup,
    main,
    nav,
    section {
      display: block;
    }

    marquee {
      display: inline-block;
    }

    address {
      display: block;
    }

    blockquote {
      display: block;
      -webkit-margin-before: 1em;
      -webkit-margin-after: 1em;
      -webkit-margin-start: 40px;
      -webkit-margin-end: 40px;
    }

    figcaption {
      display: block;
    }

    figure {
      display: block;
      -webkit-margin-before: 1em;
      -webkit-margin-after: 1em;
      -webkit-margin-start: 40px;
      -webkit-margin-end: 40px;
    }

    q {
      display: inline;
    }

    q::before {
      content: open-quote;
    }

    q::after {
      content: close-quote;
    }

    center {
      display: block;

      /* special centering to be able to emulate the html4/netscape behaviour */
      text-align: -webkit-center;
    }

    hr {
      display: block;
      border: 0 !important;
      border-top: 1px solid rgba(0,0,0,.1) !important;
      -webkit-margin-before: .5em;
      -webkit-margin-after: .5em;
      -webkit-margin-start: auto;
      -webkit-margin-end: auto;
    }

    map {
      display: inline;
    }

    video {
      object-fit: contain;
    }

    /* heading elements */
    h1 {
      display: block;
      font-size: 2em;
      -webkit-margin-before: .67em;
      -webkit-margin-after: .67em;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
      font-weight: bold;
    }

    :-webkit-any(article,aside,nav,section) h1 {
      font-size: 1.5em;
      -webkit-margin-before: .83em;
      -webkit-margin-after: .83em;
    }

    :-webkit-any(article,aside,nav,section) :-webkit-any(article,aside,nav,section) h1 {
      font-size: 1.17em;
      -webkit-margin-before: 1em;
      -webkit-margin-after: 1em;
    }

    :-webkit-any(article,aside,nav,section) :-webkit-any(article,aside,nav,section) :-webkit-any(article,aside,nav,section) h1 {
      font-size: 1em;
      -webkit-margin-before: 1.33em;
      -webkit-margin-after: 1.33em;
    }

    :-webkit-any(article,aside,nav,section) :-webkit-any(article,aside,nav,section) :-webkit-any(article,aside,nav,section) :-webkit-any(article,aside,nav,section) h1 {
      font-size: .83em;
      -webkit-margin-before: 1.67em;
      -webkit-margin-after: 1.67em;
    }

    :-webkit-any(article,aside,nav,section) :-webkit-any(article,aside,nav,section) :-webkit-any(article,aside,nav,section) :-webkit-any(article,aside,nav,section) :-webkit-any(article,aside,nav,section) h1 {
      font-size: .67em;
      -webkit-margin-before: 2.33em;
      -webkit-margin-after: 2.33em;
    }

    h2 {
      display: block;
      font-size: 1.5em;
      -webkit-margin-before: .83em;
      -webkit-margin-after: .83em;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
      font-weight: bold;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      -webkit-margin-before: 1em;
      -webkit-margin-after: 1em;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
      font-weight: bold;
    }

    h4 {
      display: block;
      -webkit-margin-before: 1.33em;
      -webkit-margin-after: 1.33em;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
      font-weight: bold;
    }

    h5 {
      display: block;
      font-size: .83em;
      -webkit-margin-before: 1.67em;
      -webkit-margin-after: 1.67em;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
      font-weight: bold;
    }

    h6 {
      display: block;
      font-size: .67em;
      -webkit-margin-before: 2.33em;
      -webkit-margin-after: 2.33em;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
      font-weight: bold;
    }

    /* tables */
    table {
      display: table;
      border-color: gray;
      border-collapse: separate;
      border-spacing: 0;
    }

    thead {
      display: table-header-group;
      border-color: inherit;
      vertical-align: middle;
    }

    tbody {
      display: table-row-group;
      border-color: inherit;
      vertical-align: middle;
    }

    tfoot {
      display: table-footer-group;
      border-color: inherit;
      vertical-align: middle;
    }

    /* for tables without table section elements (can happen with XHTML or dynamically created tables) */
    table > tr {
      vertical-align: middle;
    }

    col {
      display: table-column;
    }

    colgroup {
      display: table-column-group;
    }

    tr {
      display: table-row;
      border-color: inherit;
      vertical-align: inherit;
    }

    td,
    th {
      display: table-cell;
      vertical-align: inherit;
      padding: 1px;
      border: 1px solid rgb(221, 221, 221);
    }

    th {
      font-weight: bold;
    }

    caption {
      display: table-caption;
      text-align: -webkit-center;
    }

    /* lists */
    ul,
    menu,
    dir {
      display: block;
      list-style-type: disc;
      -webkit-margin-before: 1em;
      -webkit-margin-after: 1em;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
      -webkit-padding-start: 40px;
    }

    ol {
      display: block;
      list-style-type: decimal;
      -webkit-margin-before: 1em;
      -webkit-margin-after: 1em;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
      -webkit-padding-start: 40px;
    }

    li {
      display: list-item;
      text-align: -webkit-match-parent;
    }

    ul ul,
    ol ul {
      list-style-type: circle;
    }

    ol ol ul,
    ol ul ul,
    ul ol ul,
    ul ul ul {
      list-style-type: square;
    }

    dd {
      display: block;
      -webkit-margin-start: 40px;
    }

    dl {
      display: block;
      -webkit-margin-before: 1em;
      -webkit-margin-after: 1em;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
    }

    dt {
      display: block;
    }

    ol ul,
    ul ol,
    ul ul,
    ol ol {
      -webkit-margin-before: 0;
      -webkit-margin-after: 0;
    }

    /* form elements */
    form {
      display: block;
      margin-top: 0em;
    }

    label {
      cursor: default;
    }

    legend {
      display: block;
      -webkit-padding-start: 2px;
      -webkit-padding-end: 2px;
      border: none;
    }

    fieldset {
      display: block;
      min-width: -webkit-min-content;
      border: 2px groove ThreeDFace;
      -webkit-margin-start: 2px;
      -webkit-margin-end: 2px;
      -webkit-padding-before: .35em;
      -webkit-padding-start: .75em;
      -webkit-padding-end: .75em;
      -webkit-padding-after: .625em;
    }

    button {
      -webkit-appearance: button;
    }

    /* Form controls don't go vertical. */
    input,
    textarea,
    keygen,
    select,
    button,
    meter,
    progress {
      -webkit-writing-mode: horizontal-tb !important;
    }

    input,
    textarea,
    keygen,
    select,
    button {
      display: inline-block;
      margin: 0em;
      color: initial;
      font: -webkit-small-control;
      letter-spacing: normal;
      line-height: normal;
      text-align: start;
      text-indent: 0;
      text-shadow: none;
      text-transform: none;
      word-spacing: normal;
    }

    input[type="hidden" i] {
      display: none;
    }

    input,
    input[type="password" i],
    input[type="search" i] {
      padding: 1px;
      border: 2px inset;
      background-color: white;
      cursor: auto;
      -webkit-appearance: textfield;
      -webkit-rtl-ordering: logical;
      -webkit-user-select: text;
    }

    input[type="search" i] {
      -webkit-appearance: searchfield;
      box-sizing: border-box;
    }

    input::-webkit-textfield-decoration-container {
      display: flex;
      align-items: center;
      -webkit-user-modify: read-only !important;
      content: none !important;
    }

    input[type="search" i]::-webkit-textfield-decoration-container {
      direction: ltr;
    }

    input::-webkit-clear-button {
      -webkit-appearance: searchfield-cancel-button;
      display: inline-block;
      flex: none;
      -webkit-user-modify: read-only !important;
      -webkit-margin-start: 2px;
      opacity: 0;
      pionter-events: none;
    }

    input:enabled:read-write:-webkit-any(:focus,:hover)::-webkit-clear-button {
      opacity: 1;
      pointer-events: auto;
    }

    input[type="search" i]::-webkit-search-cancel-button {
      -webkit-appearance: searchfield-cancel-button;
      display: block;
      flex: none;
      -webkit-user-modify: read-only !important;
      -webkit-margin-start: 1px;
      opacity: 0;
      pointer-events: none;
    }

    input[type="search" i]:enabled:read-write:-webkit-any(:focus,:hover)::-webkit-search-cancel-button {
      opacity: 1;
      pointer-events: auto;
    }

    input[type="search" i]::-webkit-search-decoration {
      -webkit-appearance: searchfield-decoration;
      display: block;
      flex: none;
      -webkit-user-modify: read-only !important;
      -webkit-align-self: flex-start;
      margin: auto 0;
    }

    input[type="search" i]::-webkit-search-results-decoration {
      -webkit-appearance: searchfield-results-decoration;
      display: block;
      flex: none;
      -webkit-user-modify: read-only !important;
      -webkit-align-self: flex-start;
      margin: auto 0;
    }

    input::-webkit-inner-spin-button {
      display: inline-block;
      flex: none;
      align-self: stretch;
      cursor: default;
      opacity: 0;
      -webkit-appearance: inner-spin-button;
      -webkit-user-select: none;
      -webkit-user-modify: read-only !important;
      pointer-events: none;
    }

    input:enabled:read-write:-webkit-any(:focus,:hover)::-webkit-inner-spin-button {
      opacity: 1;
      pointer-events: auto;
    }

    keygen,
    select {
      border-radius: 5px;
    }

    keygen::-webkit-keygen-select {
      margin: 0;
    }

    textarea {
      flex-direction: column;
      padding: 2px;
      border: 1px solid;
      background-color: white;
      white-space: pre-wrap;
      cursor: auto;
      -webkit-appearance: textarea;
      -webkit-rtl-ordering: logical;
      -webkit-user-select: text;
      resize: auto;
      word-wrap: break-word;
    }

    ::-webkit-input-placeholder {
      display: block !important;
      color: darkGray;
      -webkit-text-security: none;
      pointer-events: none !important;
    }

    input::-webkit-input-placeholder {
      white-space: pre;
      word-wrap: normal;
      overflow: hidden;
      -webkit-user-modify: read-only !important;
    }

    input[type="password" i] {
      -webkit-text-security: disc !important;
    }

    input[type="hidden" i],
    input[type="image" i],
    input[type="file" i] {
      padding: initial;
      border: initial;
      background-color: initial;
      -webkit-appearance: initial;
    }

    input[type="file" i] {
      align-items: baseline;
      color: inherit;
      text-align: start !important;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
      background-color: #faffbd !important;
      background-image: none !important;
      color: #000 !important;
    }

    input[type="radio" i],
    input[type="checkbox" i] {
      margin: 3px .5ex;
      padding: initial;
      border: initial;
      background-color: initial;
    }

    input[type="button" i],
    input[type="submit" i],
    input[type="reset" i] {
      -webkit-appearance: push-button;
      -webkit-user-select: none;
      white-space: pre;
    }

    input[type="file" i]::-webkit-file-upload-button {
      margin: 0;
      font-size: inherit;
      white-space: nowrap;
      -webkit-appearance: push-button;
      -webkit-user-modify: read-only !important;
    }

    input[type="button" i],
    input[type="submit" i],
    input[type="reset" i],
    input[type="file" i]::-webkit-file-upload-button,
    button {
      align-items: flex-start;
      padding: 2px 6px 3px 6px;
      border: 2px outset ButtonFace;
      background-color: ButtonFace;
      color: ButtonText;
      text-align: center;
      cursor: default;
      box-sizing: border-box;
    }

    input[type="range" i] {
      margin: 2px;
      padding: initial;
      border: initial;
      color: #909090;
      -webkit-appearance: slider-horizontal;
    }

    input[type="range" i]::-webkit-slider-container,
    input[type="range" i]::-webkit-media-slider-container {
      display: flex;
      flex: 1;
      box-sizing: border-box;
      -webkit-user-modify: read-only !important;
      -webkit-align-contents: center;
    }

    input[type="range" i]::-webkit-slider-runnable-track {
      display: block;
      flex: 1;
      -webkit-align-self: center;
      box-sizing: border-box;
      -webkit-user-modify: read-only !important;
    }

    input[type="range" i]::-webkit-slider-thumb,
    input[type="range" i]::-webkit-media-slider-thumb {
      -webkit-appearance: sliderthumb-horizontal;
      box-sizing: border-box;
      -webkit-user-modify: read-only !important;
      display: block;
    }

    input[type="button" i]:disabled,
    input[type="submit" i]:disabled,
    input[type="reset" i]:disabled,
    input[type="file" i]:disabled::-webkit-file-upload-button,
    button:disabled,
    select:disabled,
    keygen:disabled,
    optgroup:disabled,
    option:disabled,
    select[disabled] > option {
      color: GrayText;
    }

    input[type="button" i]:active,
    input[type="submit" i]:active,
    input[type="reset" i]:active,
    input[type="file" i]:active::-webkit-file-upload-button,
    button:active {
      border-style: inset;
    }

    input[type="button" i]:active:disabled,
    input[type="submit" i]:active:disabled,
    input[type="reset" i]:active:disabled,
    input[type="file" i]:active:disabled::-webkit-file-upload-button,
    button:active:disabled {
      border-style: outset;
    }

    option:-internal-spatial-navigation-focus {
      outline: black dashed 1px;
      outline-offset: -1px;
    }

    datalist {
      display: none;
    }

    area,
    param {
      display: none;
    }

    input[type="checkbox" i] {
      -webkit-appearance: checkbox;
      box-sizing: border-box;
    }

    input[type="radio" i] {
      -webkit-appearance: radio;
      box-sizing: border-box;
    }

    input[type="color" i] {
      width: 44px;
      height: 23px;
      padding: 1px 2px;

      /* Same as native_theme_base. */
      border: 1px #a9a9a9 solid;
      background-color: ButtonFace;
      -webkit-appearance: square-button;
    }

    input[type="color" i]::-webkit-color-swatch-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      padding: 4px 2px;
      box-sizing: border-box;
      -webkit-user-modify: read-only !important;
    }

    input[type="color" i]::-webkit-color-swatch {
      flex: 1;
      border: 1px solid #777;
      background-color: #000;
      -webkit-user-modify: read-only !important;
    }

    input[type="color" i][list] {
      -webkit-appearance: menulist;
      width: 88px;
      height: 23px;
    }

    input[type="color" i][list]::-webkit-color-swatch-wrapper {
      padding-right: 24px;
      padding-left: 8px;
    }

    input[type="color" i][list]::-webkit-color-swatch {
      border-color: #000;
    }

    input::-webkit-calendar-picker-indicator {
      display: inline-block;
      width: .66em;
      height: .66em;
      padding: .17em .34em;
      -webkit-user-modify: read-only !important;
      opacity: 0;
      pointer-events: none;
    }

    input::-webkit-calendar-picker-indicator:hover {
      background-color: #eee;
    }

    input:enabled:read-write:-webkit-any(:focus,:hover)::-webkit-calendar-picker-indicator {
      opacity: 1;
      pointer-events: auto;
    }

    input[type="date" i]:disabled::-webkit-clear-button,
    input[type="date" i]:disabled::-webkit-inner-spin-button,
    input[type="datetime-local" i]:disabled::-webkit-clear-button,
    input[type="datetime-local" i]:disabled::-webkit-inner-spin-button,
    input[type="month" i]:disabled::-webkit-clear-button,
    input[type="month" i]:disabled::-webkit-inner-spin-button,
    input[type="week" i]:disabled::-webkit-clear-button,
    input[type="week" i]:disabled::-webkit-inner-spin-button,
    input:disabled::-webkit-calendar-picker-indicator,
    input[type="date" i][readonly]::-webkit-clear-button,
    input[type="date" i][readonly]::-webkit-inner-spin-button,
    input[type="datetime-local" i][readonly]::-webkit-clear-button,
    input[type="datetime-local" i][readonly]::-webkit-inner-spin-button,
    input[type="month" i][readonly]::-webkit-clear-button,
    input[type="month" i][readonly]::-webkit-inner-spin-button,
    input[type="week" i][readonly]::-webkit-clear-button,
    input[type="week" i][readonly]::-webkit-inner-spin-button,
    input[readonly]::-webkit-calendar-picker-indicator {
      visibility: hidden;
    }

    select {
      align-items: center;
      border: 1px solid;
      background-color: white;
      color: black;
      white-space: pre;
      cursor: default;
      -webkit-appearance: menulist;
      box-sizing: border-box;
      -webkit-rtl-ordering: logical;
    }

    select:-internal-list-box {
      align-items: flex-start;
      border: 1px inset gray;
      border-radius: initial;
      white-space: nowrap;
      vertical-align: text-bottom;
      -webkit-appearance: listbox;
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-user-select: none;
    }

    optgroup {
      display: block;
      font-weight: bolder;
    }

    option {
      display: block;
      min-height: 1.2em;
      padding: 0 2px 1px 2px;
      font-weight: normal;
      white-space: pre;
    }

    select:-internal-list-box:focus option:checked {
      background-color: -internal-active-list-box-selection !important;
      color: -internal-active-list-box-selection-text !important;
    }

    select:-internal-list-box option:checked {
      background-color: -internal-inactive-list-box-selection !important;
      color: -internal-inactive-list-box-selection-text !important;
    }

    select:-internal-list-box:disabled option:checked,
    select:-internal-list-box option:checked:disabled {
      color: gray !important;
    }

    output {
      display: inline;
    }

    /* meter */
    meter {
      display: inline-block;
      width: 5em;
      height: 1em;
      vertical-align: -.2em;
      -webkit-appearance: meter;
      box-sizing: border-box;
    }

    meter::-webkit-meter-inner-element {
      width: 100%;
      height: 100%;
      -webkit-appearance: inherit;
      box-sizing: inherit;
      -webkit-user-modify: read-only !important;
    }

    meter::-webkit-meter-bar {
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #ddd, #eee 20%, #ccc 45%, #ccc 55%, #ddd);
      -webkit-user-modify: read-only !important;
      box-sizing: border-box;
    }

    meter::-webkit-meter-optimum-value {
      height: 100%;
      background: linear-gradient(to bottom, #ad7, #cea 20%, #7a3 45%, #7a3 55%, #ad7);
      -webkit-user-modify: read-only !important;
      box-sizing: border-box;
    }

    meter::-webkit-meter-suboptimum-value {
      height: 100%;
      background: linear-gradient(to bottom, #fe7, #ffc 20%, #db3 45%, #db3 55%, #fe7);
      -webkit-user-modify: read-only !important;
      box-sizing: border-box;
    }

    meter::-webkit-meter-even-less-good-value {
      height: 100%;
      background: linear-gradient(to bottom, #f77, #fcc 20%, #d44 45%, #d44 55%, #f77);
      -webkit-user-modify: read-only !important;
      box-sizing: border-box;
    }

    /* progress */
    progress {
      display: inline-block;
      width: 10em;
      height: 1em;
      vertical-align: -.2em;
      -webkit-appearance: progress-bar;
      box-sizing: border-box;
    }

    progress::-webkit-progress-inner-element {
      width: 100%;
      height: 100%;
      -webkit-appearance: inherit;
      box-sizing: inherit;
      -webkit-user-modify: read-only;
    }

    progress::-webkit-progress-bar {
      width: 100%;
      height: 100%;
      background-color: gray;
      -webkit-user-modify: read-only !important;
      box-sizing: border-box;
    }

    progress::-webkit-progress-value {
      width: 50%; /* should be removed later */
      height: 100%;
      background-color: green;
      -webkit-user-modify: read-only !important;
      box-sizing: border-box;
    }

    /* inline elements */
    u,
    ins {
      text-decoration: underline;
    }

    strong,
    b {
      font-weight: 700 !important;
    }

    i,
    cite,
    em,
    var,
    address,
    dfn {
      font-style: italic;
    }

    tt,
    code,
    kbd,
    samp {
      font-family: monospace;
    }

    pre,
    xmp,
    plaintext,
    listing {
      display: block;
      margin: 1em 0;
      font-family: monospace;
      white-space: pre;
    }

    mark {
      background-color: yellow;
      color: black;
    }

    big {
      font-size: larger;
    }

    small {
      font-size: smaller;
    }

    s,
    strike,
    del {
      text-decoration: line-through;
    }

    sub {
      font-size: smaller;
      vertical-align: sub;
    }

    sup {
      font-size: smaller;
      vertical-align: super;
    }

    nobr {
      white-space: nowrap;
    }

    /* states */
    :focus {
      outline: auto 5px -webkit-focus-ring-color;
    }

    /* Read-only text fields do not show a focus ring but do still receive focus */
    html:focus,
    body:focus,
    input[readonly]:focus {
      outline: none;
    }

    applet:focus,
    embed:focus,
    iframe:focus,
    object:focus {
      outline: none;
    }

    input:focus,
    textarea:focus,
    keygen:focus,
    select:focus {
      outline-offset: -2px;
    }

    input[type="button" i]:focus,
    input[type="checkbox" i]:focus,
    input[type="file" i]:focus,
    input[type="hidden" i]:focus,
    input[type="image" i]:focus,
    input[type="radio" i]:focus,
    input[type="reset" i]:focus,
    input[type="search" i]:focus,
    input[type="submit" i]:focus,
    input[type="file" i]:focus::-webkit-file-upload-button {
      outline-offset: 0;
    }

    a:-webkit-any-link {
      color: -webkit-link;
      text-decoration: underline;
      cursor: pointer;
    }

    a:-webkit-any-link:active {
      color: -webkit-activelink;
    }

    /* HTML5 ruby elements */
    ruby,
    rt {
      text-indent: 0; /* blocks used for ruby rendering should not trigger this */
    }

    rt {
      line-height: normal;
      -webkit-text-emphasis: none;
    }

    ruby > rt {
      display: block;
      font-size: 50%;
      text-align: start;
    }

    ruby > rp {
      display: none;
    }

    /* other elements */
    noframes {
      display: none;
    }

    frameset,
    frame {
      display: block;
    }

    frameset {
      border-color: inherit;
    }

    iframe {
      max-width: 100%;
    }

    details {
      display: block;
    }

    summary {
      display: block;
    }

    summary::-webkit-details-marker {
      display: inline-block;
      width: .66em;
      height: .66em;
      -webkit-margin-end: .4em;
    }

    template {
      display: none;
    }

    bdi,
    output {
      unicode-bidi: -webkit-isolate;
    }

    bdo {
      unicode-bidi: bidi-override;
    }

    textarea[dir=auto i] {
      unicode-bidi: -webkit-plaintext;
    }
  }
}
