.FeedbackModal {
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.50);
  display: flex;
  align-items: center;
  justify-content: center;
  
  &__content {
    width: 450px;
    max-width: calc(100vw - 32px);
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.15);
    max-height: calc(100vh - 32px);
    overflow-y: auto;
  }

  &__header {
    padding: 24px;
    border-bottom: 1px solid #E2E2E2;
  }

  &__header-title {
    color: #000;
    font-size: 18px;
    font-weight: 700;
  }

  &__body {
    padding: 24px;
  }

  &__body-message {
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  &__found-answer {
    padding: 16px 0;
  }

  &__form {
    display: flex;
    flex-flow: column;
    padding: 16px 0;
    gap: 24px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 24px 24px;
  }

  &__action-btn {
    min-width: 90px;
    text-transform: uppercase;
    padding: 10px 0;
    text-align: center;
    color: #3A67AB;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.28px;
    cursor: pointer;
  }
}
