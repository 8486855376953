@import 'common';

.TopicNavigation {
  display: flex;
  justify-content: space-between;
  padding: rem(25) 0 rem(25) 0;

  flex: 0 0 auto;

  .topic-nav-wrapper {
    display: flex;
    flex-basis: 49.5%;
    justify-content: space-between;

    & > a {
      display: flex;
      flex: 1;
      text-decoration: none;
      line-height: normal;
      justify-content:flex-start;
      color: black;

      @include media-desktop {
        flex-direction: column;
      }

      @include media-mobile {
        padding: rem(10) rem(20) rem(10) rem(20);
        align-items: center;
        background-color: #f0f5f7;
      }
    }
  }

  .topic-nav-link-prev {
    align-items: flex-start;
    @include media-mobile {
      flex-direction: row;
    }
    text-align: left;
  }

  .topic-nav-link-next {
    align-items: flex-end;
    @include media-mobile {
      flex-direction: row-reverse;
    }
    text-align: right;
  }

  .topic-nav-link-direction {
    display: flex;
    font-size: rem(20);
    align-items: center;
    margin: rem(8) 0 rem(8) 0;
    flex-shrink: 0;

    div {
      font-weight: bold;
    }

    .icon.icon-nav-arrow {
      @include media-mobile {
        width: rem(10);
        min-width: rem(10);
        opacity: 0.2;
      }
      width: rem(16);
      height: rem(16);
      .fill {
        fill: black;
      }
    }

    .icon-nav-arrow-prev {
      margin-right: rem(10);
    }

    .icon-nav-arrow-next {
      transform: rotate(180deg);
      margin-left: rem(10);
    }
  }

  .topic-nav-link-title {
    font-size: rem(14);
    -ms-word-break: break-word;
    word-break: break-word;
  }
}
