@import "common";

.PdfViewer {
  .download {
    display: flex;
    align-items: center;
    padding-left: rem(20);

    @include media-mobile {
      padding-top: rem(20);
    }

    .icon {
      width: rem(24);
      margin-right: rem(5);
      cursor: pointer;
    }

    a {
      text-decoration: none;
      color: #22577D;
    }
  }

  .react-pdf__Page {
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
    margin-top: 20px;
    margin-bottom: 25px;
  }

  canvas.react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }

  .react-pdf__message--loading {
    margin-top: rem(25);
  }
}
