@import 'common';

.OwnersManualPdfs {
  user-select: none;
  flex-shrink: 0;

  .separator {
    color: inherit;
    padding: 0 rem(4);
  }

  .label {
    font-size: rem(14);
    color: black;
    padding: 0 rem(8);
    white-space: nowrap
  }

  .pdf-link {
    font-size: rem(14);
    color: #25668e;
    text-decoration: none;

    &:hover {
      color: #000;
    }
  }
}
