@import "common";

.SearchInput {
  display: flex;
  flex-shrink: 0;
  padding: rem(15) 0;
  align-items: center;
  box-shadow: inset 0 -1px #E2E2E2;

  @include media-mobile {
    padding:rem(14) rem(20);
    background-color: #f7f7f7;

    & + .topics-subtitle {
      background-color: transparent;
    }
  }

  input {
    flex: 1;
    font-size: rem(20);
    color: #000;
    background: transparent;
    border: none;
    outline:none;
    height:rem(34);
    line-height:rem(34);

    &::placeholder {
      opacity: .4;
    }

    @include media-mobile {
      font-size: rem(18);
    }
  }
  .icon.icon-search {
    width:rem(17);
    height:rem(18);
    margin-right: rem(18);

    .stroke {
      stroke: #000;
    }
  }
  button.cancel-search {
    cursor: pointer;

    @include media-desktop {
      display: none;
    }

    .icon.icon-cancel {
      width:rem(20);
      height:rem(20);

      .stroke {
        stroke: #000;
      }
    }
  }

}


