@import "common";

.NavBar {
  min-width: 100%;
  height: rem(50);

  background: #F5F7F9;

  .nav-inner {
    height: rem(50);
    flex: 0 0 rem(50);
    display: flex;
    flex-flow:row nowrap;
    align-items: center;
  }

  @include media-desktop(){
    position: absolute;
    height:rem($nav-bar-height);
    left: 0;
    background: #000;

    .nav-inner {
      height:rem($nav-bar-height);
      width: rem($content-width);

      @include media-tablet {
        width:100%;
        max-width:rem($content-width);
      }

      margin: 0 auto;
      padding: 0 rem(20);
    }
  }
  @include media-mobile(){
    box-shadow: 0 -1px 0 0 rgba(207, 218, 230, 0.5), 0 0 10px 0 rgba(0, 0, 0, 0.14);
    background-color: #fff;
    position: relative;
    z-index: 3;

    &:empty {
      display: none;
    }
  }

  .nav-head {
    flex: 0 0 auto;
  }

  .nav-logo {
    display: block;
    width:rem(80);
    height:rem(80);
    background-size: rem(55) auto;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .nav-link {
    @include media-mobile(){
      @include TouchableFeedback(rgba(20,20,20,0.5));
    }

    height: 100%;
    font-size:rem(11);
    display: flex;
    flex:1;
    flex-flow: column nowrap;
    align-items:center;
    justify-content:center;
    text-align: center;

    text-decoration: none;

    .icon {
      display: block;
      width: rem(24);
      height: rem(24);
      margin: rem(0) rem(3) rem(5);

      .active {
        display: none;
      }
      .fill {
        // see brand specific theme coloring
      }
      .stroke {
        // see brand specific theme coloring
      }
    }

    @include media-desktop(){
      flex:0 0 auto;
      height: 100%;
      display: flex;
      justify-content: center;

      font-size:rem(14);
      transition: all .2s;
      padding:0 rem(30) rem(2);
      text-transform: uppercase;
      position: relative;

      color: #fff;
      opacity:.4;

      .icon {
        display: none;
      }

      &:first-child {
        margin-left:rem(-30);
      }
    }

    &.active {
      @include media-desktop(){
        opacity:1;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width:rem(5);
          height:rem(5);
          background: white;
          border-radius: 100%;
          bottom: rem(9);
          left: 50%;
          margin-left:rem(-3);
          margin-top: 1px;
        }
      }

      // see brand specific theme coloring

      .icon .active {
        display: inherit;
      }
      .icon .inactive {
        display: none;
      }
      .icon .fill {
        // see brand specific theme coloring
      }
      .icon .stroke {
        // see brand specific theme coloring
      }
    }
  }

  .nav-foot {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

}
