.icon {
  position: relative;

  svg {
    display: block;
    width: 100%;
  }

  .active {
    display:none;
  }

  &.active {
    .inactive {
      display:none;
    }
    .active {
      display:inherit;
    }
  }
}

//TODO: mixin icon() and standard icon sizes
