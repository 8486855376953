.FeedbackInput {
  display: flex;
  border-radius: 4px 4px 0px 0px;
  background-color: rgba(51, 51, 51, 0.06);
  position: relative;
  height: 56px;
  padding: 8px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);

  &--focused {
    .FeedbackInput__placeholder {
      font-size: 12px;
      line-height: 16px;
      top: 8px;
    }
  }

  input {
    width: 100%;
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    align-self: flex-end;
  }

  &__placeholder {
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    font-weight: 400;
    position: absolute;
    transition: all .2s ease-in;
    line-height: 41px;
  }

  
}
