@import "common";

.VehicleListItem {
  display: flex;
  width: 100%;
  min-height: rem(80);
  margin: rem(7) rem(5);
  flex: 0 0 auto;
  flex-grow:1;
  flex-direction: row-reverse;
  align-items:center;
  justify-content: flex-start;

  text-decoration: none;
  background: #fff;
  font-size: rem(20);
  color: #000;
  border-radius: rem(10);

  transition: all 0.3s ease;
  padding: 0 rem(20) 0 rem(10);
  cursor: pointer;
  text-align: left;
  overflow: hidden;
  line-height:normal;

  @include media-desktop {
    border-radius: 0;
    flex-direction: column;
    flex: 0 0 33.3%;
    max-width: 33.3%; /* hack for ie 10-11 */
    padding: rem(25);
    margin: 0;
  }

  .vehicle-text {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    flex-grow:1;

    @include media-desktop {
      order: 0;
      width: 100%;
      padding-bottom: rem(14);
    }

    .title {
      display: flex;
      font-size: rem(20);
      text-decoration: none;
      color: inherit;
      width: 100%;

      @include media-desktop {
        font-weight: bold;
        line-height: 1.2;
      }
    }

    .subtitle {
      display: flex;
      font-size: rem(14);
      color: #848484;

      @include media-desktop {
        font-weight: 600;
        margin-top: rem(3);
        line-height: 1.2;
        text-transform: uppercase;
        color: #969696;
      }
    }
  }

  .heroshot {
    display: flex;
    align-items: center;
    background: transparent center center no-repeat;
    background-size: contain;
    margin-right: rem(20);
    flex-shrink: 0;
    width: rem(115);
    height: rem(80);

    @include media-desktop {
      margin: 0;
      width: rem(330);
      height: rem(215);
    }

    @include media-tablet {
      margin: 0;
      width: 100%;
      height:rem(160);
    }

    .vehicle-hero-shot {
      width: rem(115);
      max-height: rem(80);
      height: auto;

      @include media-desktop {
        width: rem(330);
        max-height: rem(215);
        height: auto;
      }

      @include media-tablet {
        width: 100%;
        height:auto;
        max-height:rem(160);
      }

      &-hide {
        display: none;
      }
    }

    .product-type-icon {
      display: flex;
      align-items: center;
      background-color: white;
      width: rem(115);
      height: rem(80);

      @include media-desktop {
        width: rem(330);
        height: rem(215);
      }

      @include media-tablet {
        width: 100%;
        height:rem(160);
      }

      .fill {
        fill: black;
      }
    }
  }
}
