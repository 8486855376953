@import "utils";

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  font-weight: 400;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote {
  &:before, &:after {
    content: '';
    content: none; } }

q {
  &:before, &:after {
    content: '';
    content: none; } }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  background: transparent;
  border: none;
  font-size:100%;
  outline: none;
  padding: 0;
}

html, body, #root {
  width:100%;
  height:100%;
  font-family:'Open Sans', sans-serif;
  min-width: rem(250);

  @include media-desktop {
    min-width:rem($content-width);

    @include media-tablet {
      width:100%;
      min-width: auto;
    }
  }
}

html {
  font-size: 20px;
  background-color: white;

  // Fix landscape auto adjust in landscape mode
  -webkit-text-size-adjust: 100%;

  @include media-lte-iphone5 {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
  min-height: 0;
  min-width: 0;
  -webkit-tap-highlight-color: transparent;
}
