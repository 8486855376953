@import 'common';

$list-color: #495360;
$icon-color: #dfdfdf;

.RelatedContentsTabView {
  z-index: 1; // should be less than in .topics-subtitle for correct shadow

  .tabs {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: rem(18);
    font-weight: bold;
    min-height: rem(28);
    color: #666666;
    text-align: center;
    box-shadow: 0 rem(1) rem(11) 0 rgba(0, 0, 0, 0.12);

    .tab {
      padding: rem(10) rem(13);
      flex: 1;

      .related-content-count {
        color: #063844;
        font-weight: bold;
        opacity: 0.3;
        padding-left: rem(5);
      }
    }

    .tab-activated {
      box-shadow: inset 0 -4px black;
      color: black;
      font-weight: bold;
    }
  }

  .tab-content {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }
}

.RelatedContentsSidePanelView {
  display: flex;
  flex-direction: column;
  width: rem(300);
  min-width: rem(300);

  @include media-tablet {
    min-width: rem(200);
    width: auto;
  }

  padding-left: rem(65);
  min-height: rem(240);
  height: auto;
  padding-bottom: rem(25);

  &.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: rem($nav-bar-height + $bread-crumb-height + 20);
  }

  &.sticky:before,
  &.sticky:after {
    content: '';
    display: table;
  }

  hr {
    display: block;
    height: rem(1);
    border: 0;
    border-top: 1px solid #ccc;
    margin: rem(5) 0;
    padding: 0;
    width: 80%;
    opacity: 0.6;
  }

  .related-contents-container {
    padding-right: rem(10);
    overflow: visible;
    margin-bottom: rem(30);
    min-height: rem(45);

    &.show-vertical-scroll {
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;

      @include media-tablet {
        overflow-y: overlay;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.single {
      overflow: visible;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: rem(10);
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: rem(10);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &:first-child {
      box-shadow: none;
      padding-top: 0;
    }
  }

  .panel-title {
    font-weight: 600;
    font-size: rem(14);
    color: #000;
    min-height: rem(38);
  }
}

.RelatedContentList {
  font-size: rem(18);
  font-weight: 100;
  line-height: rem(28);
  justify-self: center;

  @include media-desktop {
    width: rem(290);

    @include media-tablet {
      width: auto;
    }
  }

  @include media-mobile {
    padding-left: rem(20);
  }

  li {
    display: flex;
    box-shadow: inset 0 1px #cfdbe6;

    @include media-desktop {
      box-shadow: none;
    }

    &:first-child {
      box-shadow: none;
    }

    .item-inner {
      display: flex;
      flex-flow: row nowrap;
      text-decoration: none;
      font-weight: 300;
      position: relative;
      flex: 1;
      padding: rem(16) rem(20) rem(16) 0;
      align-items: center;
      cursor: pointer;

      .item-title {
        flex: 1;
        padding-right:rem(20);
        color: $list-color;
      }

      @include media-desktop {
        padding: rem(12) rem(10) rem(12) 0;
        flex-direction: row-reverse;

        .item-title {
          padding: 0 0 0 rem(9);
          line-height: rem(20);
          font-size: rem(14);
          color: #25668e;

          &:hover {
            color: #000;
          }
        }

        &:hover {
          .icon .fill {
            fill: darken($icon-color, 10%);
          }

          .icon .stroke {
            stroke: darken($icon-color, 10%);
          }
        }
      }
    }

    .item-iconbar {
      .icon {
        width: rem(16);
        height: rem(16);

        .fill {
          fill: $icon-color;
        }

        .stroke {
          stroke: $icon-color;
        }
      }

      .icon-list-pdf {
        width: rem(16);
        height: rem(20);
      }
    }
  }
}

.RelatedContentEmptyList {
  padding: rem(16) rem(20) rem(16) rem(20);
  box-shadow: inset 0 -1px #cfdbe6;
  color: $list-color;
  font-weight: bold;
}
