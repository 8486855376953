@import 'common';

.Guide {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  position: relative;

  @include media-desktop(){
    .screen-head {
      margin-bottom: rem(55);
    }

    .sticky-nav-bar {
      position: absolute;
      min-height: rem($nav-bar-height);
      top: rem($header-height);
      left: 0;
      width: 100%;

      .sticky {
        top: 0;

        .NavBar {
          top: 0;
          position: fixed;
        }
      }
    }
  }
}
