@import 'utils';
@import 'icons';
@import "../../node_modules/video.js/dist/video-js.min.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.version {
  display: none;
}

.container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include media-ie {
    height: 100%;
  }
}

%Screen {
  @extend .container;
  line-height: rem(32);

  //head with title
  .screen-head {
    position: relative;
    width: 100%;
    height: rem(65);
    padding: rem(10);
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
    align-items: center;

    @include media-desktop {
      height: rem($header-height);
      justify-content: space-between;
      padding: rem(20) 0;
    }

    @media screen and (min-width: 1100px)  {
      padding: rem(20) 80px;
    }

    @include media-mobile {
      box-shadow: inset 0 -1px #bfc7cc;
    }

    .content-preview-mode {
      background-color: #FFF0C5;
      color: #736c2f;

      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      font-size: rem(10);
      line-height: rem(14);
      font-weight: 500;

      position: fixed;
      width: rem(180);
      z-index:6;

      left: 50%;
      margin-left: rem(-90);
      top: rem(0);
      border-radius: 0 0 10px 10px;
      padding: rem(2) 0;
      box-shadow: 0 1px 0 rgba(0, 0, 0, .2);
    }
  }

  //main title
  .screen-title {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    white-space: nowrap;
    text-decoration: none;
    color: #000;

    @include media-desktop {
      font-size: rem(28);
      font-weight: bold;
      flex: 1;
      white-space: normal;

      em {
        white-space: nowrap;
        font-size: rem(14);
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.4;
      }

      span {
        font-weight: 700;
      }

      &.title-long {
        font-size: rem(24);
      }
    }

    @include media-mobile {
      font-weight: 700;
      line-height: rem(18);
      font-size: rem(20);
      padding: rem(8) rem(10) rem(10);

      em {
        font-size: rem(12);
        font-weight: 700;
      }

      span {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 700;
        line-height: 1.25em;
      }
    }
  }

  //back button os-style
  .screen-back {
    display: flex;
    flex-flow: row nowrap;
    flex: 1;

    & > * {
      @extend .screen-title;

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      text-decoration: none;
      flex-grow: 0;
      text-align: left;

      color: #000;
      cursor: pointer;

      .icon {
        width: rem(8);
        height: rem(14);
        margin-right: rem(10);
        flex-shrink: 0;

        .stroke {
          stroke: #000;
        }
      }

      @include media-mobile {
        top: rem(-8);
        font-size: rem(18);
      }
    }

    &.title-long > * {
      overflow: hidden;
      font-size: rem(15);
      white-space: normal;
      line-height: 20px;
    }
  }

  //container with padding
  .screen-content {
    position: relative;
    //warning! check to use only flex-basis: auto not flex-basis: 0 for IE11 support
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    @include media-desktop {
      width: rem($content-width);
      margin: 0 auto rem(60);

      @include media-tablet {
        width:100%;
        max-width:rem($content-width);
      }
    }
  }
  .screen-container {
    //padding: rem(20);

    width: 100%;
    height: 100%;
    position: absolute;
    //warning! check to use only flex-basis: auto not flex-basis: 0 for IE11 support
    flex: 1 1 auto;

    @include media-desktop {
      position: static;
    }

    @include media-mobile {
      overflow: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    // DO NOT REMOVE THIS, WILL CAUSE RENDER PROBLEMS ON CHROME
    transform: translateZ(0);

    display: flex;
    flex-flow: column nowrap;
  }

  //text content
  .screen-text {
    font-size: rem(16);
    h1 {
      font-size: rem(28);
      font-weight: 500;
      margin-bottom: 1em;
    }
    p {
      margin: 0.5em 0 1em 0;
    }
    strong {
      font-weight: 700;
    }
  }

  //icon toolbar in right corner
  .screen-iconbar {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    .iconbar-button {
      display: flex;
      box-sizing: content-box;
      width: rem(24);
      height: rem(24);
      padding: rem(5);
      margin: 0 0 0 rem(3);
      justify-content: center;
      align-items: center;

      .icon {
        width: rem(24);
        height: rem(24);
        .fill {
          fill: #000;
        }
        .stroke {
          stroke: #000;
        }
      }
    }
  }

  .screen-topmenu {
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 0;

    @include media-desktop {
      justify-content: flex-end;
      flex: 1;
    }

    .topmenu-trigger {
      display: none;
    }

    .topmenu-inner {
      display: flex;
      flex-flow: row nowrap;

      li {
        margin: 0 12px;

        &:last-child {
          margin-right: 0;
        }

        @media screen and (min-width: 1200px) {
          margin: 0 20px;
        }
      }
    }

    .topmenu-link {
      font-size: rem(14);
      color: #000;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
      text-wrap: nowrap;
    }

    @include media-mobile() {
      .topmenu-trigger {
        display: block;
        box-sizing: content-box;
        width: rem(24);
        height: rem(24);
        padding: rem(5);

        .icon .fill {
          fill: #000;
        }
      }
      .topmenu-inner {
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        max-height: 0;

        background: #333132;
        position: absolute;
        left: 0;
        top: rem(65);
        width: 100%;
        z-index: 10;
        flex-flow: column nowrap;

        li {
          display: flex;
          align-items: center;
          height: rem(80);
          margin: 0 rem(20);

          @include media-mobile("landscape") {
            height: rem(65);
          }
        }
      }

      .topmenu-link {
        font-size: rem(18);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: #fff;
        text-align: left;
        cursor: pointer;
        text-transform: none;

        .icon {
          width: rem(16);
          height: rem(20);

          &.icon-list-download-pdf .fill {
            fill: #ADACAD;
          }
        }
      }

      &.opened {
        .topmenu-inner {
          visibility: visible;
          opacity: 1;
          max-height: rem(500);
          display: flex;
          flex-flow: column;
          justify-content: center;
        }
        .topmenu-wrapper {
          display: block;
        }
      }
    }
  }

  // logo for Desktop version
  .screen-logo {
    height: 100%;
    flex-grow: 1;
    max-width: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 24px;
  }

  .screen-overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 11;
    background-color: #fff;
    padding: 0 rem(50);

    .overlay-text {
      padding: rem(20);
      font-size: rem(16);
    }
    .icon {
      width: rem(100);
      animation: rotateDevice 3s ease-in-out infinite;
      transform-origin: 50% 50%;

      .fill {
        fill: darken(#E0E6EC, 10%);
      }
    }
    .PrimaryButton {
      min-width: rem(180);
    }
  }
}

%Modal {
  position: absolute;
  top: rem(80);
  left: rem(20);
  right: rem(20);
  bottom: rem(60);
  border-radius: rem(10);
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;

  @include media-mobile("landscape") {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }
}

%ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.62);
  z-index: 10;
}

%Button {
  font-size: rem(20);
  line-height: 120%;
  text-decoration: none;
  padding: rem(13) rem(20);
  border-radius: rem(30);
  display: block;
  text-align: center;
  cursor: pointer;
  width: auto;

  transition: all .3s;

  @include media-desktop {
    padding: rem(13) rem(50);
  }

  &:active {
    opacity: 0.8;
  }
}

%Input {
  display: flex;
  width: auto;
  padding: rem(10);
  align-items: center;
  border: 1px solid #e2e2e2;
  border-radius: rem(2);

  input {
    flex: 1;
    font-size: rem(20);
    color: #000;
    background: transparent;
    border: none;
    outline:none;
    height:rem(34);
    line-height:rem(34);

    &::placeholder {
      opacity: .4;
    }

    @include media-mobile {
      font-size: rem(18);
    }
  }
}

%Select {
  display: inline-block;
  position: relative;
  width: auto;
  margin: 1rem auto;
  border-radius: rem(30);
  background: #fff;

  select {
    display: block;
    font-size: rem(20);
    line-height: 120%;
    text-decoration: none;
    padding: rem(13) rem(20);
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    width: auto;
    outline: none;
    transition: all .3s;
  }

  &:active {
    opacity: 0.8;
  }

  @supports (-webkit-appearance: none) or (appearance: none) or ((-moz-appearance: none) and (mask-type: alpha)) {

    select {
      appearance: none;
      padding-right: rem(35);
    }

    &:after {
      content: " ";
      display: block;
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -3px;
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-left-width: 6px;
      border-right-width: 6px;
      border-top: 6px solid black;
      pointer-events: none;
    }
  }
}

.PrimaryButton {
  @extend %Button;
  font-weight: bold;
}

.SecondaryButton {
  @extend %Button;
}

.Select {
  @extend %Select;
}

.Input {
  @extend %Input;
}

%hidden {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
  position: absolute;
}

%visible {
  width: auto;
  visibility: visible;
  opacity: 1;
  position: static;
}

@mixin TouchableFeedback($color: #000) {

  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $color 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: .2;
    transition: 0s;
  }
}

.EditorToolsScreen {
  @extend %Screen;

  .editor-content {
    display:flex;
    flex-flow: column nowrap;
    flex: 1;

    @include media-desktop {
      width: rem(600);
      margin: 0 auto;
    }

    .editor-section {
      text-align: center;
      padding: 0 rem(20);

      &.login-form {
        display: flex;
        flex-direction: column;
        padding: rem(20);
      }
      &.editor-accept {
        display: flex;
        justify-content: space-between;
        padding: rem(20);
      }

      label.section-label {
        display: block;
        background: #E2E2E2;
        margin:0 -20px;
        padding: rem(5) rem(10);

        @include media-desktop {
          text-align: left;
          margin: rem(10) 0;
          font-size: rem(18);
          background: #f9f9f9;
          border-bottom:1px solid #e2e2e2;
        }
      }

      label.input-label {
        display: block;
        text-align: left;
        padding: rem(5) 0;
        margin: rem(10) 0 0;
        font-size: rem(16);

        @include media-desktop {
          font-size: rem(18);
        }
      }

      div.editor-submit {
        padding: rem(20) 0;
      }

      div.editor-error {
        color: red;
      }

      button.editor-forgot-password {
        cursor: pointer;
        font-size: rem(16);
        color: #25668e;
        align-self: flex-end;
        margin: rem(5) 0;
      }

      .Select {
        width: 100%;

        @include media-desktop {
          //max-width:rem(250);
        }

        select {
          width: 100%;
        }
      }

      .PrimaryButton,
      .SecondaryButton {
        @include media-mobile {
          width:100%;
        }
      }
    }
  }

}

.collapsed-search {
  .screen-head, .NavBar {
    display: none !important;
  }
}

@mixin NoScrollbar {
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #000000;
  }
  &::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  &::-webkit-scrollbar-track:active {
    background: #333333;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

@keyframes rotateDevice {
  from {
    transform: rotate(45deg);
  }
  30% {
    transform: rotate(-45deg);
  }
  60% {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(45deg);
  }
}
