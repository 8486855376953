@import "common";

.Promo {
  @extend %Screen;

  align-items: center;
  min-width: 100%;

  .overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    background-size: cover;
    color: white;

    .head {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      width: 100%;
      height: rem(60);
      background-color: #252525;
      padding-left: rem(30);
      z-index: 10;

      .promo-logo {
        height: rem(60);
        width: 100%;
        background-size: contain;
        background-position: left center;
        background-repeat: no-repeat;
      }
    }

    .text {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: space-between;
      padding: 0 rem(30);
      max-width: rem(400);
      height: rem(190);
      z-index: 10;


      @include media-mobile("landscape") {
        height: rem(150);
      }

      @include media-nexus5-landscape {
        max-width: rem(500);
        height: rem(105);
      }

      h1 {
        font-size: rem(26);
        font-weight: bold;
        line-height: 1.31;
        text-align: center;

        @include media-nexus5-landscape {
          font-size: rem(20);
        }
      }

      div {
        line-height: rem(24);
        font-size: rem(18);
        font-weight: 600;
        text-align: left;
        letter-spacing: 0.3px;

        @include media-nexus5-landscape {
          font-size: rem(14);
        }
      }
    }

    .navigation {
      z-index: 10;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: rem(150);

      @include media-mobile("landscape") {
        height: rem(130);
      }

      @include media-nexus5-landscape {
        height: rem(110);
      }

      .store-badge {
        background: linear-gradient(to bottom right, transparent 50%, darkred 50%);
        display: flex;
        justify-content: center;
        align-self: center;
        flex-grow: 1;
        width: 100%;

        .download {
          align-items: center;
          justify-content: center;
          display: block;
          width: rem(169);
          height: rem(50);
          background-size: cover;
          margin: auto 0;
        }

        .ios {
          background-image: url("../images/appstore-badge.png");
        }

        .android {
          background-image: url("../images/play-badge.png");
        }
      }

      .browse {
        width: 100%;
        background-color: darkred;
        text-align: center;
        line-height: rem(14);
        padding-bottom: rem(30);
        margin-top: rem(-1);

        @include media-nexus5-landscape {
          padding-bottom: rem(20);
        }

        a {
          font-size: rem(14);
          text-decoration: none;
          color: white;
          opacity: 0.67;
        }
      }
    }
  }
}
