@import 'common';

$bottom-pos: rem(50);

.ToastContainer {
  visibility: hidden;
  position: fixed;
  background: transparent;
  pointer-events: none;
  z-index: 5;
  bottom: $bottom-pos;
  left: 0;
  height: 0;
  width: 0;
  opacity: 0;

  .toast-message-container {
    color: white;
    background-color: #6e6e6e;
    pointer-events: auto;
    width: 100%;
  }

  &.show {
    visibility: visible;
    opacity: 1;
    height: auto;
    width: 100%;
    transition: opacity 0.5s linear;
  }

  &.fadeout {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear
  }
}
