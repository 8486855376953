@import 'common';

.VehicleList {
  display: flex;
  flex:1 0 auto;
  flex-direction: column;
  position: relative;

  @include media-mobile {
    &:before, &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 100%;
      height: rem(30);
      z-index:1;
      left:0;
      pointer-events: none;
    }
    &:before {
      top: 0;
      box-shadow: inset 0 rem(10) rem(15) rem(-5) rgba(0,0,0,0.2);
    }
    &:after {
      bottom:0;
      box-shadow: inset 0 rem(-10) rem(15) rem(-5) rgba(0,0,0,0.2);
    }
  }

  @include media-desktop {
    width: rem($content-width);

    @include media-tablet {
      width:100%;
      max-width:rem($content-width);
    }

    margin: 0 auto;
    padding: 0 rem(20);
  }

  .vehicles-content {
    display: block;
    position: relative;
    flex:1 0 auto;

    @include media-mobile {
      // DO NOT REMOVE THIS: hack for ios safari for proper recalculating of flexbox height
      height: 100%;

      .vehicles-container {
        position: absolute;
        width: 100%;
        height: 100%;

        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        // DO NOT REMOVE THIS, WILL CAUSE RENDER PROBLEMS ON CHROME
        transform: translateZ(0);

        .vehicles-inner {
          position: absolute;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width:100%;
        height:100%;
        background-color: #000;
        opacity:0;
        visibility: hidden;
        transition: a
      }
      &.darkened:after {
        opacity: .5;
        visibility: visible;
      }
    }

    @include media-desktop {
      .vehicles-container .vehicles-inner {
        //width: 100%;
        display: flex;
        flex-flow: row wrap;
        margin: 0 rem(-20);
        align-items: flex-end;
      }
    }
  }

  @include media-desktop {
    .sticky-vehicles-bar {
      min-height: rem($bread-crumb-height);

      .sticky {
        background-color: white;
        left: 0;
        top: rem($nav-bar-height);
        height: rem($bread-crumb-height);
        width: 100%;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

        .vehicles-bar {
          height: rem($bread-crumb-height);
          background-color: white;
          box-shadow: none;
          width: rem($content-width);

          @include media-tablet {
            width:100%;
            max-width:rem($content-width);
          }

          padding: rem(15) rem(20);
          margin: 0 auto;
        }
      }
    }
  }
  .vehicles-bar {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    flex-shrink:0;
    text-align: left;

    font-size: rem(18);
    line-height: rem(28);

    color: #000;
    font-weight: bold;
    position: relative;
    padding: rem(17) rem(20);

    @include media-desktop {
      font-size: rem(14);
      line-height: rem(34);
      padding: rem(15) 0;
      background: none;
      text-transform: uppercase;
      color: #000;
    }

    @include media-desktop() {
      font-size: rem(14);
      line-height: rem(34);
      text-transform: uppercase;
      font-weight: bold;
    }

    .breadcrumb {
      font-size: rem(14);
      text-transform: uppercase;

      line-height: rem(34);

      font-weight:bold;
      position: relative;

      flex-shrink:0;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;
      text-align: left;

      color: #b2b2b2;

      li {
        display: flex;
        align-items: center;

        a, span {
          text-decoration: none;
          color: inherit;
          font-weight:bold;
        }
        .icon {
          display: block;
          margin: 0 rem(22);

          width: rem(4);
          height:rem(7);
          transform: rotate(180deg);

          .stroke {
            stroke: #b2b2b2;
          }
        }

        &:last-child {
          .icon {
            display: none;
          }
          color: #000;
        }
      }

      &.inactive li:last-child {
        color: inherit;
      }
    }

    .back {
      border: none;
      display: flex;
      align-items:center;
      cursor: pointer;
      background: transparent;
      text-decoration: none;
      color: #000;
      font-weight:600;

      .icon.icon-arrow-left {
        width: rem(8);
        height:rem(14);
        margin: rem(10) rem(15) rem(10) 0;

        .stroke {
          stroke: #6e6e6e;
        }
      }
    }

  }
}
